// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-templates-article-temp-tsx": () => import("./../../../src/components/templates/ArticleTemp.tsx" /* webpackChunkName: "component---src-components-templates-article-temp-tsx" */),
  "component---src-components-templates-filter-temp-tsx": () => import("./../../../src/components/templates/FilterTemp.tsx" /* webpackChunkName: "component---src-components-templates-filter-temp-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-company-business-tsx": () => import("./../../../src/pages/company-business.tsx" /* webpackChunkName: "component---src-pages-company-business-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-new-home-tsx": () => import("./../../../src/pages/new-home.tsx" /* webpackChunkName: "component---src-pages-new-home-tsx" */)
}

